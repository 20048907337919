<template>
    <div v-if="value" class="flex_left flex_nowrap">
        <span :style="{background: color || getColorHash(value)}" class="point flex_shrink0">
            <span>{{value}}</span>
        </span>
    </div>
    <span v-else>
    
    </span>
</template>

<script>
export default {
    name: "ep_color",
    props: {
        value: String,
        color: String
    },
}
</script>

<style scoped>
.point{
    /*display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 20px;
    margin-right: 6px;*/

    border-radius: 4px;
    padding: 2px 6px;
    color: white;
    font-size: 12px;
}
</style>