<template>
    <el-date-picker
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        v-model="date"
        type="date"
        placeholder=""
        @change="$emit('input', date)"
        :picker-options="{'firstDayOfWeek': 1}"
    >
    </el-date-picker>
</template>

<script>
export default {
    name: "ep_date",
    props: {
      value: String
    },
    data(){
      return {
          date: ''
      }
    },
    created(){
      this.date = this.value || ''
    },
    watch: {
        value(val){
            this.date = this.value || ''
        }
    },
    methods: {
    }
    
}
</script>

<style scoped>

</style>