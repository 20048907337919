<template>
    <el-select v-model="value_" placeholder="" filterable clearable @change="$emit('input', value_)" :class="[class_]" :multiple="multiple" :disabled="disabled">
        <el-option
            v-for="dict in list"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
        />
    </el-select>
</template>

<script>
export default {
    name: "ep_select",
    props: {
        value: String|Array,
        dict: String,
        class_: String,
        disabled: false,
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            list:[],
            value_: ''
        }
    },
    created(){
        this.value_ = this.value
        this.http_list()
    },
    watch: {
        value(val){
            this.value_ = val;
        }
    },
    methods: {
        http_list(){
            this.$http.get(`/system/dict/data/type/${this.dict}`).then((response) => {
                this.list = response.data;
            });
        }
    }
}
</script>

<style scoped>

</style>