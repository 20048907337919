<template>
    <el-col :xl="xl" :lg="other" :md="other" :sm="other" :xs="other" :gutter="1">
        <!--
        xl >= 1920
        lg >= 1200
        md >= 992
        sm >= 768
        xs < 768
    -->
        <slot></slot>
    </el-col>
</template>

<script>
export default {
    name: "ep_col",
    props: {
        xl: {
            type: Number,
            default: 24
        },
        other: {
            type: Number,
            default: 24
        }
    }
}
</script>

<style scoped>

</style>