<template>
    <el-select v-model="value_" :placeholder="placeholder || ''" filterable clearable @change="$emit('input', value_)" :class="[class_]">
        <el-option
            v-for="dict in list"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
        />
    </el-select>
</template>

<script>
export default {
    name: "ep_select_value_label",
    props: {
        value: String|Number,
        list: Array,
        class_: String,
        placeholder: String
    },
    data(){
        return {
            value_: ''
        }
    },
    created(){
        this.value_ = this.value
    },
    watch: {
        value(val){
            this.value_ = val;
        }
    },
    methods: {
    
    }
}
</script>

<style scoped>

</style>