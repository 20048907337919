<template>
    <div class="flex flex_nowrap align-items_top item" :style="{background: no_bg ? 'unset' : ''}">
        <span class="color_gray">{{label}}</span>
        <div class="margin_left_10 right">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ep_table_column",
    props: {
        label: String,
        no_bg: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.item{
    width: 100%;
    word-break: unset;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    background-color: #F6F7FB;
}
.right{
    max-width: 180px;
}
</style>