<template>
    <star-rate v-model="value_" :disabled="!!disabled" :key="value_"
               active-color="#F70B0D" :count="3"
               type="heart" :on-change="()=>{$emit('input', value_)}"/>
</template>

<script>
import StarRate from 'vue-cute-rate'
export default {
    name: "ep_rate",
    components: {StarRate},
    props: {
        value: String|Number,
        disabled: Boolean
    },
    data() {
        return {
            value_: 0,
        }
    },
    created() {
        this.value_ = this.value
    },
    watch: {
        value(val) {
            this.value_ = val;
        }
    },
}
</script>

<style scoped>
.star-main{
    margin: auto !important;
}
</style>