<template>
    <el-select v-model="userId" placeholder="" filterable clearable @change="$emit('input', userId)" :disabled="disabled">
        <el-option
            v-for="item in users"
            :key="item.userId"
            :label="item.nickName"
            :value="item.userId">
        </el-option>
    </el-select>
</template>

<script>

export default {
    name: "ep_user",
    props: {
        form: Object,
        value: String | Number,
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            userId: '',
            users:[],
        }
    },
    created(){
        this.userId = this.value;
        this.http_list()
    },
    watch: {
        value(val){
            this.userId = val;
        }
    },
    methods: {
        http_list(){
            this.$http.get('/system/user/getAllList',{}).then(response => {
                this.users = response.rows;
            });
        }
    }
}
</script>

<style scoped>

</style>