// import { sourceBaseUrl, url } from './global'
// import { getToken } from '@/utils/auth'
import moment from "moment"
import {Loading} from 'element-ui';
import ColorHash from 'color-hash'
import { numToChinese } from 'chinese-num-transfer';
const week = ['一', '二', '三', '四', '五', '六', '日']
export const LIST_STAGE = [
    {value: 0, label: '全部线索', bg: '#AE99FE'},
    {value: 1, label: '未邀约', bg: '#F4B13B'},
    {value: 2, label: '邀约中', bg: '#EFCD12'},
    {value: 3, label: '体验表', bg: '#33CCCC'},
    {value: 4, label: '跟单表', bg: '#EF6512'},
    {value: 5, label: '报读表', bg: '#34D2A1'},
    {value: 6, label: '无意向', bg: '#8E8E8E'},
]
const reg = /^([01][0-9]|2[0-3])[0-5][0-9]$/
export default {
    install(Vue, options) {
        Vue.mixin({
            data() {
                return {
                    LIST_RECORD_TYPE: [
                        {value: 0, label: '增加'},
                        {value: 1, label: '减少'},
                    ],
                    LIST_REFUND_TYPE: [
                        {value: 0, label: '预收'},
                        {value: 1, label: '续费'},
                        {value: 2, label: '活动'},
                        {value: 3, label: '余额'},
                    ],
                    LIST_JOIN_STATUS: [
                        {value: 0, label: '未参加'},
                        {value: 1, label: '已参加'},
                    ],
                    LIST_ACT_STATUS: [
                        {value: 1, label: '退费'},
                        {value: 2, label: '转余额'},
                        {value: 3, label: '转课'},
                    ],
                    LIST_TYPE_GEAR: [
                        {value: 0, label: '个人指标'},
                        {value: 1, label: '校区指标'},
                    ],
                    LIST_LONG: [
                        {value: 0, label: '否'},
                        {value: 1, label: '是'},
                    ],
                    LIST_ARRIVAL_STATUS: [
                        {value: 0, label: '未到校'},
                        {value: 1, label: '已到校'},
                        {value: 2, label: '已超期'},
                    ],
                    LIST_STAGE,
                    LIST_COURSE_STATE: [
                        {value: 1, label: '未到期', type: 'success'},
                        {value: 2, label: '快到期', type: 'warning'},
                        {value: 3, label: '超期', type: 'danger'},
                    ],
                    LIST_IS_STUDENT: [
                        {value: 1, label: '是'},
                        {value: 0, label: '否'},
                    ],
                    LIST_IS_ARRANGE: [
                        {value: 1, label: '已安排'},
                        {value: 0, label: '未安排'},
                    ],
                    LIST_IS_ARREARS: [
                        {value: 1, label: '欠款'},
                        {value: 0, label: '不欠款'},
                    ],
                    LIST_BROTHER_SISTER: [
                        {value: '女', label: '妹妹'},
                        {value: '男', label: '弟弟'},
                    ],
                    LIST_BROTHER_SISTER_OLD: [
                        {value: '女', label: '姐姐'},
                        {value: '男', label: '哥哥'},
                    ],
                    LIST_BROTHER_SISTERS: [
                        {value: 1, label: '哥哥'},
                        {value: 2, label: '姐姐'},
                        {value: 3, label: '弟弟'},
                        {value: 4, label: '妹妹'},
                    ]
                }
            },
            computed: {
                VUE_APP_BASE_API() {
                    return process.env.VUE_APP_BASE_API
                },
                header_token() {
                    return {Authorization: 'Bearer ' + getToken()}
                },
                DEVICE_() {

                    console.log(window.innerWidth)
                    return window.innerWidth > 800
                }
            },
            filters: {
                NAME(value, arr) {
                    for (let i of arr) {
                        if (i.dictCode == value) {
                            return i.dictLabel
                        }
                    }

                    for (let i of arr) {
                        if (i.value == value) {
                            return i.label || i.name
                        }
                    }
                },
                TIME(value){
                    if(reg.test(value)){
                        return value.substr(0,2)+':'+value.substr(2,2)
                    }else{
                        return value
                    }
                }
            },
            methods: {
                hasPermission(code) {
                    const all_permission = "*:*:*";
                    const permissions = JSON.parse(localStorage.getItem('permissions')|| '[]')

                    const hasPermissions = permissions.includes(all_permission) || permissions.includes(code)
                    return hasPermissions
                },
                decimal2(num) {
                    let result = parseFloat(num);
                    if (isNaN(result)) {
                        return NaN;
                    }
                    result = Math.round(num * 100) / 100;
                    return result;
                },
                common_download_file({attachmentName, fileName}) {
                    window.open(process.env.VUE_APP_BASE_API + `/common/download_file?fileName=${encodeURI(fileName)}&attachmentName=${attachmentName}`)
                },
                common_export({url, id, name}) {
                    window.open(process.env.VUE_APP_BASE_API + `/${url}/${id}?name=${encodeURI(name)}`)
                },
                SS(key) {
                    return JSON.parse(sessionStorage.getItem(key))
                },
                NAME(value, arr) {
                    for (let i of arr) {
                        if (i.dictCode == value) {
                            return i.dictLabel
                        }
                    }

                    for (let i of arr) {
                        if (i.value == value) {
                            return i.label || i.name
                        }
                    }
                },
                /**
                 * 数字转成汉字
                 * @params num === 要转换的数字
                 * @return 汉字
                 * */
                toChinesNum(num) {
                    let str =  numToChinese(num)
                    if(str.startsWith('一十')){
                        str = str.replace('一十','十')
                    }
                    return str
                    /*let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
                    let unit = ['', '十', '百', '千', '万']
                    num = parseInt(num)
                    let getWan = (temp) => {
                        let strArr = temp.toString().split('').reverse()
                        let newNum = ''
                        let newArr = []
                        strArr.forEach((item, index) => {
                            newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
                        })
                        let numArr = []
                        newArr.forEach((m, n) => {
                            if (m !== '零') numArr.push(n)
                        })
                        if (newArr.length > 1) {
                            newArr.forEach((m, n) => {
                                if (newArr[newArr.length - 1] === '零') {
                                    if (n <= numArr[numArr.length - 1]) {
                                        newNum += m
                                    }
                                } else {
                                    newNum += m
                                }
                            })
                        } else {
                            newNum = newArr[0]
                        }

                        return newNum
                    }
                    let overWan = Math.floor(num / 10000)
                    let noWan = num % 10000
                    if (noWan.toString().length < 4) {
                        noWan = '0' + noWan
                    }
                    return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)*/
                },
                UpperCase(price) {
                    const fraction = ['角', '分'];
                    const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
                    const unit = [
                        ['元', '万', '亿'],
                        ['', '拾', '佰', '仟'],
                    ];
                    let num = Math.abs(price);
                    let s = '';
                    fraction.forEach((item, index) => {
                        s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '');
                    });
                    s = s || '整';
                    num = Math.floor(num);
                    for (let i = 0; i < unit[0].length && num > 0; i += 1) {
                        let p = '';
                        for (let j = 0; j < unit[1].length && num > 0; j += 1) {
                            p = digit[num % 10] + unit[1][j] + p;
                            num = Math.floor(num / 10);
                        }
                        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
                    }

                    return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
                }
            },
            /*mounted(){
                Vue.showSearch = true
            }*/
        })
        // 定义一个全局openId变量,解决sessionStorage中的时序问题
        Vue.prototype.$gg = {
            openid: ''
        }

// 跳转
        Vue.prototype.drawer_width = function (width) {
            return window.innerWidth > 800 ? (width || '50%') : '90%'
        }
        Vue.prototype.dialog_width = function (width) {
            return window.innerWidth > 800 ? (width || '50%') : '90%'
        }
        /**
         * 千分符的转化
         */
        Vue.filter('k3', function (n) {
            if (!n) return 0;
            let str = n.toString().split('.');
            let re = /\d{1,3}(?=(\d{3})+$)/g;
            let n1 = str[0].replace(re, "$&,");
            let n2 = str[1];
            // 返回值中，注意当只有一个小数点时，且小数点后面没有数字时（也就是n2===''），要显示小数点
            return str.length > 1 && (n2 || n2 === '') ? `${n1}.${n2}` : `${n1}`;
        }),
            Vue.prototype.k3 = (row, column, n) => {
                if (!n) return n;
                let str = n.toString().split('.');
                let re = /\d{1,3}(?=(\d{3})+$)/g;
                let n1 = str[0].replace(re, "$&,");
                let n2 = str[1];
                // 返回值中，注意当只有一个小数点时，且小数点后面没有数字时（也就是n2===''），要显示小数点
                return str.length > 1 && (n2 || n2 === '') ? `${n1}.${n2}` : `${n1}`;
            },
            Vue.prototype.p2 = (row, column, cellValue, index) => {
                if (cellValue == null || cellValue === '') {
                    return ''
                }
                // % 分号
                if (typeof cellValue == 'string' && cellValue.includes('%')) {
                    return cellValue
                }
                return Vue.prototype.k3(null, null, Number(cellValue || 0).toFixed(2))
            }

        // 获取路径参数
        Vue.prototype.getQueryString = (name) => {
            let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
            let r = window.location.search.substr(1).match(reg)
            if (r != null) return unescape(r[2])
            return null
        }

        /**
         * 判断是否是微信环境
         */
        Vue.prototype.getIsWxClient = () => {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            }
            return false;
        };

        Vue.prototype.no_0_2 = (num) => {
            return Vue.prototype.no_0(Number(num).toFixed(2))
        }
        /**
         * @name: handleCutZero
         * @description:  去掉double类型小数点后面多余的0
         参数：old 要处理的字符串或double
         返回值：newStr 没有多余零的小数或字符串
         * @param {*} num
         * @return {*}num
         */
        Vue.prototype.no_0 = (num) => {
            //拷贝一份 返回去掉零的新串
            let newstr = num;
            //循环变量 小数部分长度
            //console.log(num.indexOf('.') - 1);
            let leng = num.length - num.indexOf('.') - 1;
            //判断是否有效数
            if (num.indexOf('.') > -1) {
                //循环小数部分
                for (let i = leng; i > 0; i--) {
                    //如果newstr末尾有0
                    if (
                        newstr.lastIndexOf('0') > -1 &&
                        newstr.substr(newstr.length - 1, 1) == 0
                    ) {
                        let k = newstr.lastIndexOf('0');
                        //如果小数点后只有一个0 去掉小数点
                        if (newstr.charAt(k - 1) == '.') {
                            return newstr.substring(0, k - 1);
                        } else {
                            //否则 去掉一个0
                            newstr = newstr.substring(0, k);
                        }
                    } else {
                        //如果末尾没有0
                        return newstr;
                    }
                }
            }
            return num;
        },
            Vue.prototype.getUrl = function () {
                if (window.location.href.includes('test') || window.location.href.includes('localhost') || window.location.href.includes('168')) {
                    return window.location.href
                }
                return ''
            },
            Vue.filter('p2', (cellValue) => {
                if (cellValue == null || cellValue === '') {
                    return ''
                }
                // % 分号
                if (typeof cellValue == 'string' && cellValue.includes('%')) {
                    return cellValue
                }
                return Vue.prototype.k3(null, null, (Number(cellValue) || 0).toFixed(2))
            })

        Vue.filter('p0', (cellValue) => {
            if (cellValue == null || cellValue === '') {
                return ''
            }
            // % 分号
            if (typeof cellValue == 'string' && cellValue.includes('%')) {
                return cellValue
            }
            return Vue.prototype.k3(null, null, (Number(cellValue) || 0).toFixed(0))
        })
        // 时间格式化
        Vue.filter('df',  (date, str)=> {
            if (!date) {
                return ''
            }
            return moment(date).format(str || "YYYY-MM-DD")
        })

        Vue.filter('df_',  (date, str)=> {
            if (!date) {
                return ''
            }
            return moment(date).format(str || "YYYY-MM-DD HH:mm")
        })

        Vue.filter('getTimeWeek', (date)=>{
            if (!date) {
                return ''
            }
            let m = moment(date)
            return `${m.format( "YYYY-MM-DD")} 星期${week[m.format('E')-1]} ${m.format(' HH:mm')}`

        })

        Vue.filter('getTimeWeek_', (date)=>{
            if (!date) {
                return ''
            }
            let m = moment(date)
            return `${m.format( "YYYY-MM-DD")} 星期${week[m.format('E')-1]}`

        })

        Vue.prototype.getWeek = function (date, s) {
            if (!date) {
                return ''
            }
            let m = moment(date)
            return `${m.format( "MM-DD")} 星期${week[m.format('E')-1]}`
        }

        Vue.prototype.getTime = function (cellValue, s) {
            if (!cellValue) {
                return ''
            }
            return moment(cellValue).format(s || "YYYY-MM-DD")
        }

        Vue.prototype.fmt = function (row, column, cellValue, index) {
            if (!cellValue) {
                return ''
            }
            return moment(cellValue).format("YYYY-MM-DD")
        }
        Vue.prototype.fmt_ = function (row, column, cellValue, index) {
            if (!cellValue) {
                return ''
            }
            return moment(cellValue).format("YYYY-MM-DD HH:mm")
        }

        Vue.prototype.go_to = function (item) {
            console.log(item)
            if (!item || !item.url_type) {
                return
            }
            if (item.url_type == 1 && item.url1) {
                // 跳转表单
                this.$router.push({
                    path: '/form_view/' + item.url1,
                    query: {

                        // yp 放开
                        microPageId: this.getQueryString('microPageId') || this.$route.query.liveCodeId || this.$route.params.id || '',

                        liveCodeId: this.$route.query.liveCodeId,
                        openId: this.getQueryString('openId') || '',
                        userId: this.getQueryString('userId') || ''
                    }
                })
            } else if (item.url_type == 2 && item.url2) {
                // 跳转微页
                this.$router.push({
                    path: '/page_view/' + this.$route.params.id + '/' + item.url2,
                    query: {
                        liveCodeId: this.$route.query.liveCodeId,
                        openId: this.getQueryString('openId') || '',
                        userId: this.getQueryString('userId') || '',

                        // yp 放开
                        microPageId: this.getQueryString('microPageId') || this.$route.query.liveCodeId || '',
                    }
                })
            } else if (item.url_type == 3 && item.url3) {
                // 跳转外链
                window.open(item.url3.startsWith('http') ? item.url3 : ('https://' + item.url3))

            }
        };
        Vue.prototype.goWarn = function (goOut) {
            // swal(goOut, "", "warning");
            Vue.prototype.$message({
                message: goOut,
                type: 'error'
            });
        };
        //警告框弹出
        Vue.prototype.goOut = function (goOut) {
            // swal(goOut, "", "warning");
            Vue.prototype.$message({
                message: goOut,
                type: 'error'
            });
        };
        //成功框弹出
        Vue.prototype.goOk = function (success) {
            // swal(success, "", "success");
            Vue.prototype.$message({
                message: success,
                type: 'success'
            });
        };

        // 异常框弹出
        Vue.prototype.goOut = function (goOut) {
            this.$message({
                showClose: true,
                type: 'error',
                message: goOut
            });
        };
        // 成功框弹出
        Vue.prototype.goOk = function (success) {
            this.$message({
                showClose: true,
                type: 'success',
                message: success
            });
        };
        // 警告框弹出
        Vue.prototype.goWarn = function (msg) {
            this.$message({
                showClose: true,
                type: 'error',
                message: msg
            });
        };

        // 消息框弹出
        Vue.prototype.goMessage = function (type, msg) {
            this.$message({
                showClose: true,
                type: type,
                message: success
            });
        };


        //获取随机数
        Vue.prototype.getUUID = function (success) {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";

            var uuid = s.join("");
            return uuid
        };

        // 开始
        let loading;
        Vue.prototype.startLoading = function () {
            loading = Loading.service({
                lock: false,
                spinner: '',
                background: 'rgba(0,0,0,0.7)'
            })
        }
        // 结束
        Vue.prototype.endLoading = function () {
            loading.close();
        }

        // 根据字符传获取颜色
        Vue.prototype.getColorHash = (str) => {
            return new ColorHash().hex(str); // '#8796c5'
        }
    }
}
